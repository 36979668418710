.bg-custom {
    background-color: #328fa8 !important;
}

.card-header-sm {
    padding: .45rem 1.25rem !important;
}

.table-min-width {
    min-width: 800px;
}

.cell-min-width {
    min-width: 100px;
}

.white {
    color:#fff;
}